import React from 'react';
import {MainRouter} from "./http/routes/index";
import {SnackbarProvider, MaterialDesignContent} from "notistack";
import {Provider as ReduxProvider} from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {store} from "./redux";
import {RealTimeProvider} from "./providers";
import { render } from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {getEnv} from "./helpers/functions";
import {styled} from "@mui/styles";

const theme = createTheme({
    spacing: (n)=>`${n*10}px`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 769,
            lg: 1280,
            xl: 1800,
        },
    },
    props: {
        MuiWithWidth: {
            initialWidth: 'lg',
        },
    },
});

function FallbackComponent() {
    return <div>An error has occurred</div>
}

const myFallback = <FallbackComponent />

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#5DC887',
        fontSize: 13,
        fontWeight: 500,
        minHeight: 32,
        padding: 7,
        borderRadius: 6,
        '& div[id="notistack-snackbar"]': {
            padding: 0,
            gap: '10px'
        }
    },
    // '&.notistack-MuiContent-error': {
    //     backgroundColor: '#970C0C',
    // },
}));

const Root = () => {
    return (
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <RealTimeProvider>
                    <SnackbarProvider
                        maxSnack={1}
                        Components={{
                            success: StyledMaterialDesignContent,
                            error: StyledMaterialDesignContent,
                        }}
                        iconVariant={{
                            success: <DoneIcon/>
                        }}
                    >
                        <Sentry.ErrorBoundary
                            fallback={myFallback}>
                            <MainRouter />
                        </Sentry.ErrorBoundary>
                    </SnackbarProvider>
                </RealTimeProvider>
            </ReduxProvider>
        </ThemeProvider>
    )
}

Sentry.init({
    dsn: "https://bd4a0cbb8ad94eadb76e0d5c505b57d1@o1193088.ingest.sentry.io/6315254",
    integrations: [new BrowserTracing()],
    environment: getEnv('REACT_APP_ENV'),

    beforeSend(event, hint) {

        if (getEnv('REACT_APP_ENV') == 'local') {
            return null;
        }

        const error = hint.originalException
        const msg = (error?.message || event?.message).toString()
        if (msg.indexOf('ResizeObserver') !== -1) {
            return null;
        }

        if (event.exception) {
            Sentry.showReportDialog({
                lang: 'en',
                labelName: 'Name',
                labelEmail: 'Email',
                labelComments: 'What happened?',
                labelSubmit: 'Submit'
            });
        }
        return event;
    },
    tracesSampleRate: 1.0,
});

// ReactDOM.createRoot(document.getElementById('root')).render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
// )

const DoneIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" width="24" height="24" rx="4" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17 8.85753L11.0487 17L7 13.1917L7.90419 12.1417L10.8639 14.9257L15.9259 8L17 8.85753Z"
                  fill="white"/>
        </svg>
    )

}

render(<Root/>, document.getElementById('root'));