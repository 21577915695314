export const fetchPriceListAction = (payload) => {
    return {
        type: 'FETCH_PRICELIST',
        payload
    }
}

export const fetchPriceListFilterDataAction = (payload) => {
    return {
        type: 'FETCH_PRICELIST_FILTER_DATA',
        payload
    }
}

export const resetPriceListAction = () => {
    return {
        type: 'RESET_PRICELIST'
    }
}

export const resetPriceListFilterDataAction = () => {
    return {
        type: 'RESET_PRICELIST_FILTER_DATA'
    }
}

export const setPriceListFilterValueAction = (payload) => {
    return {
        type: 'SET_PRICELIST_FILTER_VALUE',
        payload
    }
}

export const togglePriceListFilterAction = () => {
    return {
        type: 'TOGGLE_PRICELIST_FILTER'
    }
}