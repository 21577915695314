const initialState = {
    data: [],
    filter: [],
    loading: false,
    filter_params: {
        id_brand: [],
        id_reason_status: [],
        id_suppliers_status: [],
        id_subcategory: [],
    }
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_SERVICE_ACTION':
            return {
                ...state,
                data: payload,
            }

        case 'RESET_SERVICE_PRODUCT':
            return {
                ...state,
                data: [],
            }

        case 'FETCH_SERVICE_FILTER_ACTION':
            return {
                ...state,
                filter: {
                    ...payload,
                },
            }

        case 'FETCH_FILTER_PARAMS':
            return {
                ...state,
                filter_params: payload
            }

        default:
            return state;
    }
}

const service = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    return {
        ...mainStates,
    }
}

export default service