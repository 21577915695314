import {createStore, applyMiddleware} from "redux";
import root_store from "./root_store";
import {getEnv} from "../helpers/functions";

const logMiddleware = (store) => (dispatch) => (action) => {
    if (getEnv("REACT_APP_ENV") === "local") {
        console.log(action.type, store.getState());
    }
    return dispatch(action);
}

const store = createStore(root_store, applyMiddleware(logMiddleware));

export default store