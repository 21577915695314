import React from "react";
import Icon from "../icon";
import {Box} from "@mui/material";

function PageLoader({ error, pastDelay, timedOut}) {
    if (error) {
        return <Box>Error: ${error}</Box>;
    } else {
        return (
            <Box id="preloader">
                <Box className="square-box-loader">
                    <Icon name="preloads" width="41px" />
                </Box>
            </Box>
        );
    }
}

export default PageLoader;