import React, {memo, useCallback, useEffect} from 'react';
import {useSelector} from "react-redux";
import {Layout} from "../../components/admin_layout";
import {useFetchAuthUser, useHandleChannelAction, useLogoutOnTokenExpire} from "../../helpers/hooks";
import {redirectToLogin, windowReload} from "../../helpers/functions";
import {Box} from "@mui/material";

const auth = (Wrapped) => {
    return memo((props) => {
        const auth = useSelector((state) => state.app.auth);
        const fetchAuthUser = useFetchAuthUser();
        const {isAuth, user} = auth

        useHandleChannelAction('change-status', useCallback(({status}) => {
            windowReload()
        }, [user]))

        useLogoutOnTokenExpire()

        useEffect(() => {

            if (isAuth === true) {
                return;
            }

            if (isAuth === false) {
                redirectToLogin();
            }

            fetchAuthUser();
        }, [isAuth]);

        if (isAuth !== true || !user?.id) {
            return null;
        }

        if(user.status === 'blocked' || user.user_status === 'blocked'){
            return <Box textAlign="center">Account is Blocked</Box>
        }

        return (
            <Layout>
                <Wrapped {...props} />
            </Layout>
        )
    })
}

export default auth