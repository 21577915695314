import React from 'react';
import {Box} from "@mui/material";

const Icon = ({name, dim, ...props}) => {
    const dimProps = {};
    if (dim !== undefined) {
        const splitDim     = dim.split(',');
        dimProps['width']  = splitDim[0];
        dimProps['height'] = splitDim[1]
    }
    
    return <Box component="img" src={`/images/${name}.svg`} style={dimProps} {...props}/>
}

export default Icon