import {combineReducers} from "redux";
import {app, tree, stock, ballanceInfo, service, statistics, orders, pricelist, p2pTrade, catalog} from "../redux/reducers";

const root_store = combineReducers({
    app,
    tree,
    stock,
    service,
    ballanceInfo,
    statistics,
    orders,
    pricelist,
    p2pTrade,
    catalog
});

export default root_store;