import React from 'react';
import {Box} from "@mui/material";
import style from "./Style.module.scss"
import BlockLoaderV2 from "./block-loader-v2";
import Icon from "../icon";

const BlockLoader = ({height = 200, ...props}) => {

    const styles = {
        height: height
    }

    //return <BlockLoaderV2 height={height} {...props}/>

    return (
        <Box display="flex"
             alignItems="center"
             justifyContent="center"
             className={style.block_loader}
             style={styles}
             {...props}>
            <Box className={style.loader_inner}>
                <Icon name="preloads" width="41px" />
            </Box>
        </Box>
    )
}

export default BlockLoader;