import {objVal, sortBy} from "../../helpers/functions";

const windowModalDefProps = {
    collapse: false,
    open: false,
    width:670,
    height: 510,
    x:0,
    y: 0,
    zIndex: 1
}

const datesDefaultStates = {from: null, to: null};

const initialState = {
    next_checkpoints: [],
    constants: null,
    settings: null,
    pusher_channel_data: {
        action: null,
        data: []
    },
    auth: {
        user: {},
        isAuth: null
    },
    ballance_info: [],
    penalty_info: [],
    modal_width: 'auto',
    open_sidebar: false,
    open_filter_slidebar: false,
    show_filter_btn: false,

    window_modal: {
        pricelist: {
            name: 'Price List',
            ...windowModalDefProps
        },
        statistics: {
            name: 'Statistics',
            ...windowModalDefProps
        }
    },
    calendar: {},
    dates: datesDefaultStates,
}

const setPusherChannelData = (payload) => {
    return {
        ...payload
    }
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_SETTINGS':
            return {
                ...state,
                settings: payload
            }

        case 'FETCH_MAIN_DATA':
            return {
                ...state,
                ...payload,
                window_modal: {
                    ...state.window_modal,
                    ...payload?.window_modal || {}
                },
                dates: {from: payload.from, to: payload.to}
            } 
        
        case 'FETCH_CHECKPOINTS': 
            return {
                ...state,
                next_checkpoints: payload,
                // pusher_channel_data: {
                //     action: 'switch-stock',
                //     data: payload
                // },
            }

        case 'SET_OPEN_SIDEBAR':
            return {
                ...state,
                open_sidebar: payload
            }

        case 'FETCH_BALLANCE_INFO':
            return {
                ...state,
                ballance_info: payload
            }

        case 'SET_PENALTY_INFO':
            return {
                ...state,
                penalty_info: payload
            }

        case 'SET_PUSHER_CHANNEL_DATA':
            return {
                ...state,
                pusher_channel_data: setPusherChannelData(payload)
            }

        case 'FETCH_CONST':
            return {
                ...state,
                constants: payload
            }

        case 'SET_MODAL_WIDTH':
            return {
                ...state,
                modal_width: payload
            }

        case 'SET_OPEN_FILTER_SLIDEBAR':
            return {
                ...state,
                open_filter_slidebar: payload
            }

            // pricelist: {
            //     size: {width:0, height: 0},
            //     position: {x:0, y: 0}
            // }

        case 'SET_WINDOW_MODAL_OPTIONS':
            const {modal, ...props} = payload

            return {
                ...state,
                window_modal: {
                    ...state.window_modal,
                    [modal]: {
                        ...state.window_modal[modal],
                        ...props
                    }
                }
            }

        case 'SHOW_FILTER_BTN':
            return {
                ...state,
                show_filter_btn: payload
            }

        case 'RESET_SLIDER_FILTER':
            return {
                ...state,
                show_filter_btn: false,
                open_filter_slidebar: false
            }

        case "SET_DATES":
            return {
                ...state,
                dates: payload   
            }

        default:
            return state;
    }
}

const updateAuthStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_USER':
            const status = Object.keys(payload).length;
            return {
                isAuth: status ? true : false,
                user: payload
            }

        case 'LOGOUT':
            return {
                isAuth: false,
                user: {}
            }

        case 'LOGIN':
            return {
                isAuth: true,
                user: payload
            }

        case 'UPDATE_USER_DATA':
            return {
                ...state.auth,
                user: {
                    ...state.auth.user,
                    ...payload
                }
            }

        case 'UPDATE_USER_DATA_AVATAR':
            return {
                user: {
                    avatar: payload
                }
            }

        default:
            return state.auth;
    }
}

const app = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    const authStates = updateAuthStates(state, action);

    return {
        ...mainStates,
        auth: authStates
    }
}

export default app;