import axios from 'axios';
import {trimPath, getEnv, redirectToLogin} from "../helpers/functions";
import {logout} from "../redux/actions/auth";
import * as http from "http";

const apiUrl = getEnv('REACT_APP_API_URL');

axios.defaults.baseURL = apiUrl;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401 && !error.response.config.url.includes("logout")) {
                redirectToLogin()
            }
        }

        return error;
    }
);

export const fetcher = url => fetch(
    apiUrl + prepareRequestUrl(url),
    {
            headers: getHeaders(),
            // mode: 'no-cors',
        }
).then(r => r.json())

export async function getRequest(url, params = {}) {

    const resp = await axios.get(prepareRequestUrl(url), {
        headers: getHeaders(),
        params: params,
        responseType: 'json'
    });

    return extractData(resp, url);
}

export async function dwnRequest(url, params = {}, api = true) {
    // if (api != true) {
    //
    //     axios.defaults.baseURL = '/';
    //
    //     console.log(axios.defaults.baseURL)
    // }
    const resp = await axios.get(prepareRequestUrl(url), {
        headers: getHeaders(),
        params: params,
        responseType: 'blob',
        baseURL: api ? apiUrl : getEnv('REACT_APP_API_HOSTNAME')
    });

    //axios.defaults.baseURL = apiUrl;

    return resp;
}

export async function postRequest(url, data = {}) {
    const config = {
        headers: getHeaders(data?.token)
    }
    
    const resp = await axios.post(prepareRequestUrl(url), data, config);
    return extractData(resp, url);
}

export async function deleteRequest(url, id) {
    const resp = await axios.delete(prepareRequestUrl(url) + `/${id}`,);
    return extractData(resp, url);
}

export const prepareRequestUrl = (url) => {
    return `/${trimPath(url)}`;
}

export const getHeaders = (token = '') => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    let apiToken = token || localStorage.getItem('api_token');

    if (apiToken) {
        Object.assign(headers, {
            'Authorization': 'Bearer ' + apiToken
        });
    }

    return headers;
}

function extractData(resp, url) {
    if (!resp?.data) {
        throw new Error(`Could not fetch ${url}`);
    }
    return resp?.data;
}

export const uploadPath = (path) => {
    return getEnv('REACT_APP_API_HOSTNAME') + prepareRequestUrl('uploads/' + path);
}