export const fetchAuthUser = (payload) => {
    return {
        type: 'FETCH_USER',
        payload
    }
}

export const logout = () => {
    return {
        type: 'LOGOUT'
    }
}

export const login = (payload) => {
    return {
        type: 'LOGIN',
        payload
    }
}

export const updateUserData = (payload) => {
    return {
        type: 'UPDATE_USER_DATA',
        payload
    }
}

export const updateUserDataAvatar = (payload) => {
    return {
        type: 'UPDATE_USER_DATA_AVATAR',
        payload
    }
}