const initialState = {
    ballance_data: [],
    penalty: [],
    preorders: [],
    reason_return: [],
    subscription_preorders: {
        data: [],
        percent: 0
    },
    service: null,
    period: '',
    from: null,
    to: null
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {

        case 'FETCH_BALLANCE_INFO':
            return {
                ...state,
                ballance_data: payload
            }

        case 'SET_PENALTY_INFO':
            return {
                ...state,
                penalty: payload
            }

        case 'FETCH_SUBSCRIPTION_PREORDERS':
            return {
                ...state,
                subscription_preorders: payload
            }


        case 'RESET_SUBSCRIPTION_PREORDERS':
            return {
                ...state,
                subscription_preorders: initialState.subscription_preorders
            }

        case 'SET_SERVICE_INFO':
            return {
                ...state,
                service: payload
            }

        case 'SET_PERIOD':
            return {
                ...state,
                ...payload
            }

        case 'SET_PREORDERS':
            return {
                ...state,
                preorders: payload
            }

        case 'SET_REASON_RETURN':
            return {
                ...state,
                reason_return: payload
            }

        case 'RESET_BALLANCE_INFO':
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}

const ballanceInfo = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);

    return {
        ...mainStates,
    }
}

export default ballanceInfo;