import React from "react";
import {Box} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = {
    root: {
        margin: "0 15px 0 10px",
        height: "calc(100vh - 60px)"
    }
}

const Layout = withStyles(styles)(({classes, children}) => {
    return (
        <Box className={`${classes.root}`}>
            {children}
        </Box>
    )
})

export default Layout;