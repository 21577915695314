import {arrIntersect, countArr, empty, int, float, getObjByField, keyBy, objVal, sortBy, existByKeyVal} from "../../../helpers/functions";

const defaultStateValues = {
    data: [],
    model_chars: [],
    filter_params: {
        from: null,
        to: null
    },
    chart_params: {
        id_brand: null,
        id_category: null,
        id_subcategory: null,
        selected_chars: []
    },
    modal_statistics: []
}

const initialState = {
    ...defaultStateValues
}

const updateModelChars = (model_chars, {id_subcategory}, {subcategory, chars}) => {

    if (subcategory.id !== id_subcategory) {
        return model_chars
    }

    const data = chars.data

    const keyByIdChars = keyBy(model_chars, 'id') || {}
    objVal(data).map((value) => {

        const {id: id_char, childs} = value

        if (keyByIdChars?.[id_char]) {
            const keyByIdChilds = keyBy(keyByIdChars[id_char].childs, 'id')

            childs.map(({id: id_child, ...props}) => {
                if (!keyByIdChilds?.[id_child]) {
                    keyByIdChars[id_char]['childs'].push({id: id_child, ...props})
                }
            })
        } else {
            keyByIdChars[id_char] = {...value}
        }
    })

    return objVal(keyByIdChars)
}

const updateStatsData = (data, {brand, category, subcategory, chars: receivedChars, total: price}) => {

    const update = (arr, param, additionalProps = null) => {
        const newArr = [];
        objVal(arr).map(({ id, ...props }) => {
            let updateProps = {};
            if (id === param) {
                updateProps = {
                    total: props.total + price,
                };

                if (!empty(additionalProps)) {
                    Object.entries(additionalProps).map((v, k) => {
                        updateProps[v[0]] = v[1];
                    });
                }
            }

            newArr.push({
                id,
                ...props,
                ...updateProps,
            });
        });

        return sortBy(newArr, "page_up");
    };

    const hasIntersectedChars = (chars) => {
        return (
            objVal(chars).filter(({ intersect }) => {
                return (
                    countArr(arrIntersect(intersect, receivedChars.intersect)) === countArr(receivedChars.intersect)
                );
            }).length > 0
        );
    }

    let brands;
    let categories = {...(data?.categories || {})}
    let subcategories = {...(data?.subcategories || {})}

    if (existByKeyVal(data.brands, 'id', brand.id)) {
        brands = update(data.brands, brand.id)
    } else {
        brands = [...data.brands, {...brand, total: float(price)}]
    }

    if (!categories?.[brand.id]) {
        categories[brand.id] = []
    }

    if (existByKeyVal(categories[brand.id], 'id', category.id)) {
        categories = {
            ...categories,
            [brand.id]: update(categories[brand.id], category.id),
        };
    } else {
        categories = {
            ...categories,
            [brand.id]: [
                ...categories[brand.id],
                {
                    ...category,
                    total: price,
                },
            ],
        };
    }

    if (!subcategories?.[category.id]) {
        subcategories[category.id] = []
    }

    if (existByKeyVal(subcategories[category.id], "id", subcategory.id)) {
        const { chars } = getObjByField(subcategories[category.id], "id", subcategory.id);
        let subcatChars = [];

        if (hasIntersectedChars(chars)) {
            objVal(chars).map(({ intersect, ...props }) => {
                let updateProps = {};
                if (
                    countArr(arrIntersect(intersect, receivedChars.intersect)) === countArr(receivedChars.intersect)
                ) {
                    updateProps = {
                        total: props.total + price,
                    };
                }
                subcatChars.push({ ...props, ...updateProps, intersect });
            });
        } else {
            subcatChars = [
                ...chars,
                {
                    total: price,
                    intersect: receivedChars.intersect,
                },
            ];
        }

        subcategories = {
            ...subcategories,
            [category.id]: update(subcategories[category.id], subcategory.id, { chars: subcatChars }),
        };
    } else {
        const totals = {
            total: price
        };
        subcategories = {
            ...subcategories,
            [category.id]: [
                ...subcategories[category.id],
                {
                    ...subcategory,
                    ...totals,
                    chars: [{
                            ...totals,
                            intersect: receivedChars.intersect,
                    }],
                },
            ],
        };
    }

    return {
        brands, categories, subcategories
    }
}

const setSubcatCharsDiagram = ({subcategories, ...props}, {diagram, id_subcategory, id_category}) => {

    const subcategory = subcategories[id_category]
    const index = subcategory.findIndex((v) => v.id === id_subcategory)
    return {
        ...props,
        subcategories: {
            ...subcategories,
            [id_category]: [
                ...subcategory.slice(0, index),
                {...subcategory[index], chars: diagram},
                ...subcategory.slice(index + 1)
            ]
        }
    }
}
const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_STATISTICS':
            return {
                ...state,
                data: payload,
                model_chars: []
            }

        case 'FETCH_MODAL_STATISTICS':
            return {
                ...state,
                modal_statistics: payload
            }

        case 'RESET_STATISTICS':
            return {
                ...defaultStateValues,
                ...(payload ? {filter_params: {...state.filter_params, ...payload}} : {})
            }

        case 'RESET_MODAL_STATISTICS':
            return {
                ...state,
                modal_statistics: [],
                ...(payload ? {filter_params: {...state.filter_params, ...payload}} : {})
            }

        case 'RESET_STATISTICS_SELECTIONS':
            return {
                ...state,
                chart_params: defaultStateValues.chart_params
            }

        case 'ADD_STATISTICS_DATA':
            return {
                ...state,
                model_chars: updateModelChars(state.model_chars, state.chart_params, payload),
                data: updateStatsData(state.data, payload),
            }

        case 'SET_STATISTICS_FILTER_PARAMS':
            return {
                ...state,
                filter_params: {
                    ...state.filter_params,
                    ...payload
                }
            }

        case 'SET_STATISTICS_CHART_PARAMS':

            let {chart_params} = state

            let payloadKeyName = Object.keys(payload)[0]
            const keys = Object.keys(chart_params);
            const index = keys.findIndex((name) => name == payloadKeyName)

            const resets = {}
            objVal(keys.slice(index+1)).map((name) => {
                resets[name] = name == 'selected_chars' ? [] : null
            })

            return {
                ...state,
                chart_params: {
                    ...state.chart_params,
                    ...resets,
                    ...payload
                },
                model_chars: []
            }

        case 'REMOVE_STATISTICS_IDS_CHARS':
            const selected_chars = refreshPosition(state.chart_params.selected_chars.filter(
                ({id_val}) => int(id_val) !== int(payload)
            ))

            return {
                ...state,
                chart_params: {
                    ...state.chart_params,
                    selected_chars
                }
            }

        case 'SET_STATISTICS_IDS_CHARS':
            const {position, id_val} = payload

            const current_chars = state.chart_params.selected_chars;
            const lastSelected = current_chars[current_chars.length-1]

            if (!lastSelected?.position && position > 1) {
                return {...state}
            }

            const lastPosition = lastSelected?.position

            if (lastPosition && lastPosition !== position && (lastPosition < position && position - 1 !== lastPosition)) {
                return {...state}
            }

            let filteredSelectedChars = []

            state.chart_params.selected_chars.forEach((v) => {
                if (int(v.position) < int(position)) {
                    filteredSelectedChars.push(v)
                }
            });

            if (!existByKeyVal(state.chart_params.selected_chars, 'id_val', id_val)) {
                filteredSelectedChars.push({...payload})
            }

            return {
                ...state,
                chart_params: {
                    ...state.chart_params,
                    selected_chars: sortBy(filteredSelectedChars, "position"),
                },
            };

        case "FETCH_CHARS_DIAGRAM_STATISTICS":
            return {
                ...state,
                model_chars: payload.chars,
                data: setSubcatCharsDiagram(state.data, payload)
            };

        default:
            return state;
    }
}

const refreshPosition = (items) => {
    return items.map((v, k) => {
        v.position = k
        return v
    })
}

const statistics = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    return {
        ...mainStates,
    }
}

export default statistics