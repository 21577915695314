export const fetchConstants = (payload) => {
    return {
        type: 'FETCH_CONST',
        payload
    }
}

export const fetchSettings = (payload) => {
    return {
        type: 'FETCH_SETTINGS',
        payload
    }
}

export const setPusherChannelDataAction = (payload) => {
    return {
        type: 'SET_PUSHER_CHANNEL_DATA',
        payload
    }
}

export const setModalWidthAction = (payload) => {
    return {
        type: 'SET_MODAL_WIDTH',
        payload
    }
}

export const setCheckpointsAction = (payload) => {
    return {
        type: 'FETCH_CHECKPOINTS',
        payload
    }
}

export const setOpenSidebarAction = (payload) => {
    return {
        type: 'SET_OPEN_SIDEBAR',
        payload
    }
}

export const setOpenFilterSlidebarAction = (payload) => {
    return {
        type: 'SET_OPEN_FILTER_SLIDEBAR',
        payload
    }
}

export const showFilterBtnAction = (payload) => {
    return {
        type: 'SHOW_FILTER_BTN',
        payload
    }
}

export const resetSliderFilterAction = () => {
    return {
        type: 'RESET_SLIDER_FILTER'
    }
}

export const fetchMainDataAction = (payload) => {
    return {
        type: "FETCH_MAIN_DATA",
        payload,
    };
};

export const setWindowModalOptionsAction = (payload) => {
    return {
        type: "SET_WINDOW_MODAL_OPTIONS",
        payload,
    };
};

export const setDatesAction = (payload) => {
    return {
        type: "SET_DATES",
        payload,
    };
};