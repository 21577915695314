import {objVal, sortBy} from "../../../helpers/functions";

const initialState = {
    packing_list: []
}

const handleChannelData = ({packing_list}, payload) => {
    const {
        id_product,
        last_qty,
        price,
        quantity,
        product_code: code,
        product_name,
        page_up,
        weight,
        fixed_quantity
    } = payload

    const productIndex = packing_list.findIndex((v) => v.id_product === id_product);

    const current_qty = packing_list?.[productIndex]?.quantity || 0;

    const total_qty =
        last_qty === quantity
            ? current_qty + quantity
            : current_qty - last_qty + quantity;

    const product = {
        id_product, code, product_name, quantity: total_qty, price, page_up, weight, fixed_quantity
    }

    let updatedProductsList = packing_list;

    if (productIndex === -1) {
        updatedProductsList.push(product);
    } else {
        updatedProductsList = [
            ...packing_list.slice(0, productIndex),
            product,
            ...packing_list.slice(productIndex + 1),
        ];
    }
    return objVal(sortBy(updatedProductsList, 'page_up'));
}

const orders = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_PACKING_LIST_ACTION':
            return {
                ...state,
                packing_list: payload
            }


        case 'UPDATE_PACKING_LIST_ACTION':
            return {
                ...state,
                packing_list: handleChannelData(state, payload),
            }

        default:
            return state;
    }
}

export default orders