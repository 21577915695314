import moment from "moment";
import {updateArray} from "../../../helpers/functions";

const initialState = {
    bids: [],
    can_edit: false,
    tab: 'current',
    completed_type: 'all',
    models: [],
    filter_params: {
        models: []
    },
    period: moment(new Date()).add(-1, 'month').format("YYYY-MM-DD")
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {

        case 'SET_P2P_TAB':
            return {
                ...state,
                ...initialState,
                tab: payload
            }

        case 'SET_P2P_COMPLETED_TYPE':
            return {
                ...state,
                completed_type: payload
            }

        case 'FETCH_P2P_BIDS':
            return {
                ...state,
                ...payload
            }

        case 'CREATE_OR_UPDATE_P2P_BID':

            const index = state.bids.findIndex(({id}) => parseInt(id) === parseInt(payload.id))

            let updatedData = state.bids

            if (index !== -1) {
                updatedData = [...state.bids.slice(0, index), {...state.bids[index], ...payload}, ...state.bids.slice(index + 1)]
            } else {
                updatedData.push(payload)
            }

            return {
                ...state,
                bids: [...updatedData]
            }

        case 'REMOVE_P2P_BID':
            return {
                ...state,
                bids: [...state.bids.filter(({id}) => Number(id) !== Number(payload))]
            }

        case 'REMOVE_P2P_BID_PRODUCT':
            return {
                ...state,
                bids: [...state.bids.filter(({id_product}) => Number(id_product) !== Number(payload))]
            }

        case 'UPDATE_P2P_BID':
            return {
                ...state,
                bids: updateArray(state.bids, payload)
            }

        case 'FETCH_P2P_FILTERS_META':
            return {
                ...state,
                models: payload.models
            }

        case 'SET_P2P_FILTER_PARAMS':
            return {
                ...state,
                filter_params: payload
            }

        case 'RESET_P2P':
            return {
                ...initialState
            }

        case 'SET_PERIOD':
            return {
                ...state,
                period: payload
            }

        default:
            return state;
    }
}

const p2pTrade = (state = initialState, action) => {
    return {
        ...updateMainStates(state, action)
    }
}

export default p2pTrade