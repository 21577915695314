import {isNumber} from "chart.js/helpers";

const initialState = {
    data: [],
    filter_data: {
        brands: [],
        chars: []
    },
    filter_params: {
        id_brand: null,
        id_category: null,
        id_subcategory: null,
        char: null
    },
    open_filter: true
}

const pricelist = (state = initialState, {type, payload}) => {

    switch (type) {
        case 'FETCH_PRICELIST':
            return {
                ...state,
                data: payload
            }

        case 'FETCH_PRICELIST_FILTER_DATA':
            return {
                ...state,
                filter_data: {
                    ...state.filter_data,
                    ...payload
                }
            }

        case 'SET_PRICELIST_FILTER_VALUE':
            const obj = {}
            Object.entries(payload).forEach((v) => {
                const value = v[1]
                obj[v[0]] = isNumber(value) ? parseInt(value) : value
            })

            return {
                ...state,
                filter_params: {
                    ...state.filter_params,
                    ...obj
                }
            }

        case 'RESET_PRICELIST_FILTER_DATA':
            return {
                ...state,
                filter_params: {
                    ...initialState.filter_params
                }
            }

        case 'TOGGLE_PRICELIST_FILTER': {
            return {
                ...state,
                open_filter: !state.open_filter
            }
        }

        case 'RESET_PRICELIST':
            return {
                ...state,
                filter_params: initialState.filter_params,
                filter_data: initialState.filter_data
            }

        default:
            return state;
    }
}

export default pricelist;