import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "@reach/router";
import {route} from "../routes";

const guest = (Wrapped) => {
    return (props) => {
        const {isAuth}  = useSelector((state) => state.app.auth);
        const navigate = useNavigate();

        useEffect(() => {
            if (isAuth) {
                navigate(route('dashboard'));
            }
        }, [isAuth])

        if (isAuth) {
            return null;
        }

        return <Wrapped {...props} />
    }
}

export default guest