import React from "react";
import {Link} from "@reach/router";
import style from './Notfound.module.scss';

const NotFound = () => {
    return (
        <div className={style.body}>
            <div className={style.container}>
                <div className={style.content}>
                    <h1 className={style.h1}>404</h1>
                    <div className={style.title}>Not Found</div>
                    <Link className={style.a} to="/">Start with home page</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound;