export const renderModel = (e, categories, models) => {
    categories.map(({model, id}, k) => {
        if(Number(id) === Number(e.target.value)){
            if(e.target.checked){
                model.map(({name, id}, k) => {
                    models.push({
                        id,
                        name,
                    });
                });
            }else{
                model.map(({name, id}, k) => {
                    models.splice( models.indexOf(model[id]) , 1)
                });
            }
        }
    });

    return models;
};

export const extractImeiFromObject = (obj) => {
    const number = []
    const data = ["serial_number", "imei1", "imei2", "console", "fourteen_number", "eighteen_number", "serial_22"];

    data.forEach((item, k) => {
        if (obj[item]) {
            number.push(obj[item])
        }
    });

    return number;
};