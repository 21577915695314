import React from "react";

const RealTimeContext = React.createContext({
    pusher: null
});

const {
    Provider: RealTimeContextProvider,
    Consumer: RealTimeContextConsumer
} = RealTimeContext;

export {
    RealTimeContextProvider,
    RealTimeContextConsumer,
    RealTimeContext
}