const initialState = {
    before_state: [],
    folders: {},
    folders_products: [],
    folder_data: {
        type: null,
        params: {}
    },
    active_folder: null
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {

        case 'RESET_FOLDER_CATALOG_DATA':
            return {
                ...state,
                folder_data: {
                    type: null,
                    params: {}
                },
                active_folder: null
            }

        case 'SET_FOLDER_CATALOG_DATA':
            return {
                ...state,
                folder_data: payload.folder_data,
                active_folder: payload.active_folder
            }

        default:
            return state;
    }
}

const catalog = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    return {
        ...mainStates,
    }
}

export default catalog;