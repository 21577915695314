import React, {useEffect, useState} from "react";
import {RealTimeContextProvider} from './context';
import {useDispatch, useSelector} from "react-redux";
import {setPusherChannelDataAction} from "../../redux/actions/app";
import {getEnv} from "../../helpers/functions";
import {useSubscribeAndBindChannel} from "../../helpers/hooks";

const RealTimeProvider = ({children}) => {
    const [pusher, setPusher] = useState(null)
    const {user}   = useSelector((state) => state.app.auth)
    const dispatch = useDispatch();

    const pusherProviderHandler = (payload) => {
        if (getEnv('REACT_APP_ENV') === 'local') {
            console.log(payload)
        }
        dispatch(setPusherChannelDataAction(payload))
    }

    useEffect(() => {
        if (!user?.id) return

        import("../../config/pusher").then(pusher => {
            setPusher(pusher.pusher)
        });
    }, [user])

    useSubscribeAndBindChannel(
        "supplier-handler-channel",
        "supplier-handler",
        pusherProviderHandler,
        [],
        pusher
    );

    useSubscribeAndBindChannel(
        `private-supplier-handler-channel-${user.id}`,
        "supplier-handler",
        pusherProviderHandler,
        [],
        pusher
    );

    useSubscribeAndBindChannel(
        `stock_provider_${user.id_provider}`,
        "change-stock",
        (data) => {
            pusherProviderHandler({
                action: "change-stock",
                data,
            });
        },
        [],
        pusher
    );

    useSubscribeAndBindChannel(
        "switch_stock",
        "switch-stock",
        (data) => {
            pusherProviderHandler({
                action: "switch-stock",
                data,
            });
        },
        [],
        pusher
    );

    return (
        <RealTimeContextProvider value={{
            pusher: pusher
        }}>
            {children}
        </RealTimeContextProvider>
    )
}

export default RealTimeProvider
